<template>
  <v-container class="app-page">
    <v-card class="overflow-hidden text-center">
      <h1 class="titulo mb-10 pb-10">Ayuda</h1>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="panel" :disabled="disabled" multiple accordion="true">
            <v-expansion-panel>
              <v-expansion-panel-header>Descripción</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>
                  Es una forma no presencial de comunicación con el departamento de RRHH. Donde se le da acceso al empleado a datos relacionados con la liquidación de sus haberes y descentraliza la generación de reportes.
                  El sistema de recibos web, le permite al empleado:
                </p>
                <ul>
                  <li>Acceder a su recibo de sueldo.</li>
                  <li>Ver historial de liquidaciones recibidas.</li>
                  <li>Ver detalles de calculo de conceptos como Aguinaldo, Salario Vacacional, IRPF, Reliquidaciones, y otros conceptos configurados especificamente.</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Configuración Inicial</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>El usuario web de cada empleado,(*) por defecto estará definido por su documento de identidad</p>
                <p>Ej: empleado Nº 1, Juan Perez, CI: 1.234.567-8 el usuario por defecto será: 12345678</p>
                <ul>
                  <li>test</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Acceso a utilidades del sistema.</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>El usuario web de cada empleado,(*) por defecto estará definido por su documento de identidad</p>
                <p>Ej: empleado Nº 1, Juan Perez, CI: 1.234.567-8 el usuario por defecto será: 12345678</p>
                <ul>
                  <li>test</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-spacer class="py-4"></v-spacer>
          <v-subheader>APP</v-subheader>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Links de descargas</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>El usuario web de cada empleado,(*) por defecto estará definido por su documento de identidad</p>
                <p>Ej: empleado Nº 1, Juan Perez, CI: 1.234.567-8 el usuario por defecto será: 12345678</p>
                <ul>
                  <li>test</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Datos de contacto y soporte</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>El usuario web de cada empleado,(*) por defecto estará definido por su documento de identidad</p>
                <p>Ej: empleado Nº 1, Juan Perez, CI: 1.234.567-8 el usuario por defecto será: 12345678</p>
                <ul>
                  <li>test</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-spacer class="py-4"></v-spacer>
          <v-subheader>Feedback o problema?</v-subheader>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Feedback</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>El usuario web de cada empleado,(*) por defecto estará definido por su documento de identidad</p>
                <p>Ej: empleado Nº 1, Juan Perez, CI: 1.234.567-8 el usuario por defecto será: 12345678</p>
                <ul>
                  <li>test</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Informar sobre un problema</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>El usuario web de cada empleado,(*) por defecto estará definido por su documento de identidad</p>
                <p>Ej: empleado Nº 1, Juan Perez, CI: 1.234.567-8 el usuario por defecto será: 12345678</p>
                <ul>
                  <li>test</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ayuda",
  data() {
    return {
      loading: false,
      panel: [0],
      disabled: false,
      readonly: false,
    };
  },

  created() {},

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Ayuda");
    this.$store.commit("stopProcessing");
  },

  methods: {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5) !important;
  border-radius: 20px 20px 0px 20px !important;
}
</style>
